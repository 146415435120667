import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import PressFeatured from "../components/press/press-featured.js"
import PressArchive from "../components/press/press-archive.js"
import PressPraise from "../components/press/press-praise.js"

const Press = () => {
  //All data for Wet Ink Press
  const ql_press = useStaticQuery(graphql`
    query {
      # Featured Articles entries
      allContentfulFeaturedArticle(sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            author
            publication
            url
            date(formatString: "LLLL") # Event Date/Time
            thumbnail {
              file {
                url
              }
            }
          }
        }
      }
      # Press Archive entries
      allContentfulArchivedArticle(sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            author
            publication
            url
            date(formatString: "LLLL") # Event Date/Time
          }
        }
      }

      # Praise for Wet Ink
      contentfulPressInfo {
        praiseForWetInk {
          json
        }

        # Press photos
        pressPhotos {
          file {
            url
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Press" />
      <PressFeatured pressData={ql_press.allContentfulFeaturedArticle.edges} />
      <PressArchive pressData={ql_press.allContentfulArchivedArticle.edges} />
      <PressPraise
        pressPraise={ql_press.contentfulPressInfo.praiseForWetInk.json}
        pressPhotos={ql_press.contentfulPressInfo.pressPhotos}
      />
    </Layout>
  )
}

export default Press
