import React from "react"

import Style from "./press.module.scss"

/**
 * Press Archive (Default): List of archived press articles
 */
const PressArchive = props => {
  return (
    <div className={Style.pressArchivePane}>
      <h2>PRESS ARCHIVE</h2>
      {props.pressData.map((article, key) => {
        return <ArchivedArticle pressData={article.node} key={key} />
      })}
    </div>
  )
}

export default PressArchive

/* =================================================== */

/**
 * ArchivedArticle: A single archived press article
 */
const ArchivedArticle = props => {
  return (
    <>
      <hr />
      <a href={props.pressData.url}>
        <b>{props.pressData.title.toUpperCase()}</b>, by{" "}
        {props.pressData.author}
        <br />
        <i>{props.pressData.publication}</i> - {props.pressData.date}
      </a>
    </>
  )
}
