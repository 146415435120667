import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Style from "./press.module.scss"

/**
 * PressPraise (Default): Misc press praise, quotes, etc.
 */
const PressPraise = props => {
  return (
    <div className={Style.pressPraisePane}>
      <h2>PRAISE FOR WET INK</h2>
      <hr />
      {documentToReactComponents(props.pressPraise)}
      <hr />
      <h2>PRESS PHOTOS</h2>
      Click thumbnails to download
      <br />
      {props.pressPhotos.map((photo, id) => {
        return (
          <>
            <br />
            <br />
            <a href={`https:${photo.file.url}`} download="press-photo">
              <img
                src={`https:${photo.file.url}`}
                style={{ width: "50%", height: "auto" }}
                key={id}
                alt="Press"
              />
            </a>
          </>
        )
      })}
      <hr />
    </div>
  )
}

export default PressPraise
