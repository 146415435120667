import React from "react"

import Style from "./press.module.scss"

/**
 * PressFeatured (Default): Displays all featured articles
 */
const PressFeatured = props => {
  return (
    <div className={Style.pressFeaturedPane}>
      <h2>FEATURED ARTICLES</h2>
      <hr />
      <div className={Style.featureGrid}>
        {props.pressData.map((article, key) => {
          return <FeaturedArticle pressData={article.node} key={key} />
        })}
      </div>
    </div>
  )
}

export default PressFeatured

/* =================================================== */

/**
 * FeaturedArticle: A single featured article
 */
const FeaturedArticle = props => {
  return (
    <a href={props.pressData.url}>
      <div style={{ position: "relative", width: "100%" }}>
        <img
          src={`http:${props.pressData.thumbnail.file.url}`}
          align="left"
          alt={props.pressData.title}
        />
        <div className={Style.middle}>
          <div className={Style.text}>
            <b>{props.pressData.title.toUpperCase()}</b>
            <br />
            ———
            <br />
            {props.pressData.author}
            <br />
            <i>{props.pressData.publication}</i>
          </div>
        </div>
      </div>
    </a>
  )
}
